import styled from 'styled-components';

const XL = styled('p')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 22,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 24,
  },
}));

const LG = styled('p')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 20,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 22,
  },
}));

const MD = styled('p')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));

const SM = styled('p')(({ theme }) => ({
  fontSize: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));

export const Text = { XL, LG, MD, SM };
