import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { useUser } from '../react-query/user/useUser';

import { Layout } from '../components/layouts/Layout';
import { HomePage } from '../pages/home/HomePage';
import { PrivacyPolicyPage } from '../pages/privacy-policy/PrivacyPolicyPage';
import { AccountDetailsPage } from '../pages/account-details/AccountDetailsPage';
import { TermsOfServicePage } from '../pages/terms-of-service/TermsOfServicePage';
import { ProfilePage } from '../pages/profile/ProfilePage';

import { ProjectProfilePage } from '../pages/project/profile/ProjectProfilePage';
import { ProjectCreatePage } from '../pages/project/create/ProjectCreatePage';
import { ProjectEditDetailsPage } from '../pages/project/edit-details/ProjectEditDetailsPage';
import { MyProjectsPage } from '../pages/my-projects/MyProjectsPage';
import { ProjectOnboardPage } from '../pages/project-onboard/ProjectOnboardPage';

import { GenerateProfilePage } from '../pages/generate-profile/GenerateProfilePage';
import { NotificationsPage } from '../pages/notifications/NotificationsPage';

import { ContactPage } from '../pages/contact/ContactPage';

import { ErrorPage } from '../pages/error/ErrorPage';

import { routes } from './routes';

export const AuthenticatedRouter: React.FC = () => {
  const { data: user } = useUser();

  useEffect(() => {
    if (!user) return;
    hotjar.initialized() && hotjar.identify(user.uid, user);
  }, [user]);

  return (
    <Layout isLoading={!user}>
      <Routes>
        <Route path={routes.home()} element={<HomePage />} />
        <Route path={routes.privacyPolicy()} element={<PrivacyPolicyPage />} />
        <Route path={routes.termsOfService()} element={<TermsOfServicePage />} />
        <Route path={routes.accountDetails.root()} element={<AccountDetailsPage />} />
        <Route path={routes.notifications.root()} element={<NotificationsPage />} />

        <Route
          path={routes.profile.root()}
          element={<Navigate to={routes.profile.byId(user?.username || '')} />}
        />
        <Route path={routes.profile.byId(':id')} element={<ProfilePage />} />

        <Route path={routes.projectOnboard.root()} element={<ProjectOnboardPage />} />

        <Route path={routes.project.root()} element={<ProjectCreatePage />} />
        <Route
          path={routes.project.editById(':id')}
          element={<ProjectEditDetailsPage />}
        />
        <Route path={routes.project.byId(':project')} element={<ProjectProfilePage />} />
        <Route path={routes.myProjects.root()} element={<MyProjectsPage />} />

        <Route
          path={routes.generateWeb3Profile.root()}
          element={<GenerateProfilePage />}
        />

        <Route path={routes.contact()} element={<ContactPage />} />

        <Route path={routes.error(':status')} element={<ErrorPage />} />
        <Route path='*' element={<Navigate to={routes.error(404)} />} />
      </Routes>
    </Layout>
  );
};
