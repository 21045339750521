import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';
import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../../../../react-query/project/useProject';

import { IsVerified } from '../../../../../../components/common/IsVerified/IsVerified';

import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';
import { Heading3 } from '../../../../../../components/ui/text/Heading';

export const ProjectName: React.FC = () => {
  const { t } = useTranslation();
  const { data: project, isLoading, error } = useProjectByParamId();
  const { data: reputation } = useReputationWithAddress();

  const isNonNft = isNonNftProjectError(error);

  const renderContent = () => {
    if (isLoading)
      return (
        <Loader>
          <H4>L</H4>
        </Loader>
      );

    return (
      <>
        <H4>{isNonNft ? t('projectPage.nonNftProjectProfile') : project?.title}</H4>
        {reputation?.isProjectVerified && <IsVerified />}
      </>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 24,
  },
}));

const H4 = styled(Heading3)(({ theme }) => ({
  fontWeight: 900,
  lineHeight: 1.19,
}));

const Loader = styled(LoadingBackground)(({ theme }) => ({
  color: 'transparent !important',
  width: '75%',
  borderRadius: 25,
  maxWidth: 380,
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(25),
    maxWidth: theme.pxToVw(380),
  },
}));
