import React from 'react';

import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../../../../react-query/project/useProject';

import { SocialIcons } from '../../../../../../components/profile/SocialIcons/SocialIcons';

import { envs } from '../../../../../../config/envs';

export const ProjectInfoSocialIcons: React.FC = () => {
  const { data: project, isLoading, error } = useProjectByParamId();

  const isNonNft = isNonNftProjectError(error);

  if (isNonNft) return null;

  const { twitterUrl, openseaUrl, discordUrl, externalUrl, contractAddress } =
    project || {};

  return (
    <SocialIcons
      externalUrl={externalUrl}
      twitterUrl={twitterUrl}
      openseaUrl={openseaUrl}
      discordUrl={discordUrl}
      shareUrl={`${envs.blockemWebsiteUrl}/p/${contractAddress}`}
      isClaimedAddress
      isLoading={isLoading}
    />
  );
};
