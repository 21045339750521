import React from 'react';
import styled from 'styled-components';

export const ErrorTextBase = styled('span')(({ theme }) => ({
  color: theme.colors.status.error,
  fontSize: 11,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(11),
  },
}));

const ErrorText = styled(ErrorTextBase)(({ theme }) => ({
  color: theme.colors.status.error,
  fontSize: 11,
}));

interface Props {
  error?: boolean | string | Error;
}

export const ErrorMessage: React.FC<Props> = ({ error, ...otherProps }) => {
  if (!error || typeof error === 'boolean') return null;
  const message = typeof error === 'string' ? error : error.message;
  return <ErrorText {...otherProps}>{message}</ErrorText>;
};
