import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useReputation } from '../reputation/useReputation';

import { ProjectApi } from '../../api/ProjectApi';
import { QueryKey } from '../query-keys';
import { routes } from '../../router/routes';
import { isValidEthAddress } from '../../utils/address-utils';
import { AxiosError } from 'axios';

export const useProject = (contractAddress?: string | null) => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(
    !!contractAddress && !isValidEthAddress(contractAddress)
  );

  const isAddress = !!contractAddress && !!isValidEthAddress(contractAddress);

  const { data: reputation, isLoading } = useReputation(
    isAddress ? contractAddress : undefined
  );

  const { isContract } = reputation || {};

  useEffect(() => {
    if (!contractAddress || !isAddress || !reputation || enabled) return;

    if (!isContract) return navigate(routes.profile.byId(contractAddress));
    setEnabled(true);
  }, [isAddress, reputation, enabled, contractAddress, isContract, navigate]);

  const query = useQuery(
    QueryKey.Project(contractAddress!),
    () => ProjectApi.getProject(contractAddress!),
    {
      onError: (err: any) => {
        if (err.response.status === 404) {
          navigate(routes.error(404), { state: { error: 'projectNotFound' } });
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled,
      retry: false,
    }
  );

  return { ...query, isLoading: query.isLoading || isLoading };
};

export const useProjectByParamId = () => {
  const { project } = useParams();

  return useProject(project);
};

export const isNonNftProjectError = (
  error: AxiosError<{ message: string }> | undefined | null
) => error?.response?.data?.message === 'NON_NFT_PROJECT_PROFILE';
