import { axiosInstance } from '../config/axios';
import { NFT, PaginatedResponse, Project, ProjectDetails } from '../types';
import { apiRoutes } from './routes';
import { SharedApiMapper } from './SharedApiMapper';
import {
  ProjectDetailsResponseApi,
  ProjectListResponseApiItem,
  ProjectResponseApi,
} from './types';

export type UserNFTsResponse = PaginatedResponse<NFT>;

interface CommonPopularProject {
  uid: string;
  title: string;
}
interface PopularProjectResponseItemApi extends CommonPopularProject {
  contract_address: string;
  profile_photo_url: string | null;
  contract_created_at: number;
  popularity_count: number;
}

interface PopularProjectResponseApi {
  by_endorsements: PopularProjectResponseItemApi[];
  by_followers: PopularProjectResponseItemApi[];
}

export interface PopularProject extends CommonPopularProject {
  contractAddress: string;
  profilePhotoUrl: string | null;
  contractCreatedAt: number;
  popularityCount: number;
}
class ProjectApiConfig {
  private readonly apiMapper = {
    projectDetails: (response: ProjectDetailsResponseApi): ProjectDetails => {
      const { token_supply, floor_price, holders_count } = response;

      return {
        tokenSupply: token_supply,
        floorPrice: floor_price,
        holdersCount: holders_count,
      };
    },

    popularProjects: (
      response: PopularProjectResponseApi
    ): { byFollowers: PopularProject[]; byEndorsments: PopularProject[] } => {
      const { by_followers, by_endorsements } = response;

      const mapSigleItem = ({
        contract_address,
        contract_created_at,
        profile_photo_url,
        popularity_count,
        ...rest
      }: PopularProjectResponseItemApi): PopularProject => ({
        ...rest,
        contractAddress: contract_address,
        contractCreatedAt: contract_created_at,
        profilePhotoUrl: profile_photo_url,
        popularityCount: popularity_count,
      });

      return {
        byFollowers: (by_followers || []).map(mapSigleItem),
        byEndorsments: (by_endorsements || []).map(mapSigleItem),
      };
    },
  };

  getProjectsByAddressOrUsername = async (addressOrUsername: string) => {
    const { data } = await axiosInstance.get<ProjectListResponseApiItem[]>(
      apiRoutes.projects.byAddressOrUsername(addressOrUsername)
    );

    return SharedApiMapper.projects(data || []);
  };

  getProject = async (address: string): Promise<Project> => {
    const { data } = await axiosInstance.get<ProjectResponseApi>(
      apiRoutes.project.profile.byAddress(address)
    );
    return SharedApiMapper.project(data);
  };

  getProjectDetails = async (address: string) => {
    const { data } = await axiosInstance.get<ProjectDetailsResponseApi>(
      apiRoutes.project.details.byAddress(address)
    );
    return this.apiMapper.projectDetails(data);
  };

  getEndorsmentsCount = async (projectUid: string): Promise<number> => {
    const { data } = await axiosInstance.get(
      apiRoutes.project.endorsments.totalCount(projectUid)
    );
    return data.endorsements_count;
  };

  getFollowersCount = async (projectUid: string): Promise<number> => {
    const { data } = await axiosInstance.get(
      apiRoutes.project.followers.totalCount(projectUid)
    );
    return data.followers_count;
  };

  getPopularProjects = async () => {
    const { data } = await axiosInstance.get<PopularProjectResponseApi>(
      apiRoutes.project.popular()
    );
    return this.apiMapper.popularProjects(data);
  };

  subscribe = async (email: string) => {
    await axiosInstance.post(apiRoutes.project.subscribe(), { email });
  };
}

export const ProjectApi = new ProjectApiConfig();
