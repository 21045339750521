import React from 'react';
import styled from 'styled-components';

import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../../../react-query/project/useProject';

import { EditProjectActions } from '../ProjectInfo/EditAndFollowProject/EditProjectActions';
import { ProjectReputationSummary } from './AnalysisSummary/ProjectReputationSummary';
import { ProjectReputationDetails } from './ReputationDetails/ProjectReputationDetails';

export const ProjectReputation: React.FC = () => {
  const { error } = useProjectByParamId();

  const isNonNft = isNonNftProjectError(error);

  return (
    <Col isNonNft={isNonNft}>
      <ActionsWrapper>
        <EditProjectActions />
      </ActionsWrapper>
      <ProjectReputationSummary />
      {!isNonNft && <ProjectReputationDetails />}
    </Col>
  );
};

const Col = styled('div')<{ isNonNft: boolean }>(({ theme, isNonNft }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  width: '100%',
  [theme.min(theme.breakpoints.md)]: {
    alignSelf: 'flex-end',
    width: '45vw',
    position: 'absolute',
    right: 0,
    top: isNonNft ? 160 : 200,
  },
  [theme.min(theme.breakpoints.lg)]: {
    alignSelf: 'flex-end',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(10),
    flex: 1,
    maxWidth: theme.pxToVw(751),
    top: isNonNft ? theme.pxToVw(160) : theme.pxToVw(200),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 10,
    maxWidth: 751,
    top: isNonNft ? 160 : 200,
  },
}));

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.min(theme.breakpoints.md)]: {
    display: 'block',
    marginLeft: 'auto',
  },
}));
