import { useQuery } from 'react-query';

import { ProjectApi } from '../../api/ProjectApi';
import { useAddress } from '../../providers/AddressProvider/AddressProvider';
import { isValidEthAddress } from '../../utils/address-utils';

import { QueryKey } from '../query-keys';
import { useProject } from './useProject';

export const useProjectDetails = (contractAddress?: string | null) => {
  const { data, isLoading, error } = useProject(contractAddress);

  const query = useQuery(
    QueryKey.ProjectDetails(contractAddress!),
    () => ProjectApi.getProjectDetails(contractAddress!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled:
        !!data && !error && !!contractAddress && isValidEthAddress(contractAddress),
    }
  );

  return {
    ...query,
    isLoading: isLoading || query.isLoading,
  };
};

export const useProjectDetailsByAddress = () => {
  const { address: contractAddress } = useAddress();

  return useProjectDetails(contractAddress);
};
