import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLoadImage } from '../../../../../hooks/useLoadImage';
import { useEndorsments } from '../../../../../react-query/user/useEndorsments';
import { useProjectFollowers } from '../../../../../react-query/project/useProjectFollowers';

import { PopularProject } from '../../../../../api/ProjectApi';

import { LoadingBackground } from '../../../../../components/ui/loaders/LoadingBackground';
import { ProfileBadges } from '../../../../../components/common/ProfileBadges/ProfileBadges';

import { defaultProfileImage } from '../../../../../assets';
import { routes } from '../../../../../router/routes';
import { formatDate } from '../../../../../utils/date-utils';
import { Heading5 } from '../../../../../components/ui/text/Heading';
import { Text } from '../../../../../components/ui/text/Text';

interface Props {
  project: PopularProject;
  isFollow: boolean;
}

export const PopularProjectCard: React.FC<Props> = ({ project, isFollow }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { profilePhotoUrl, title, contractCreatedAt, contractAddress, popularityCount } =
    project;

  const { src, isError, isLoaded } = useLoadImage(
    profilePhotoUrl || defaultProfileImage,
    defaultProfileImage
  );

  const { data: endorsments, isLoading } = useEndorsments(project.uid);

  const { data: followers, isLoading: followersIsLoading } = useProjectFollowers(
    project.uid
  );

  const onClick = () => navigate(routes.project.byId(contractAddress));

  return (
    <Wrapper onClick={onClick}>
      <ImageWrapper>
        <Image src={src} position={profilePhotoUrl && !isError ? 'top' : 'center'} />
        {!isLoaded && <ImageLoader />}
      </ImageWrapper>
      <Title>{title}</Title>
      <Footer>
        <CreatedAtText as='span'>{`${t('common.created')} ${formatDate(
          contractCreatedAt,
          {
            month: 'short',
            year: 'numeric',
          }
        )}`}</CreatedAtText>

        <ProfileBadges
          followers={isFollow ? followers : endorsments}
          totalCount={popularityCount}
          onClick={() => {}}
          isLoading={isFollow ? followersIsLoading : isLoading}
          showTotalCountAs='badge'
        />
      </Footer>
    </Wrapper>
  );
};

export const PopularProjectCardLoader: React.FC = () => (
  <Wrapper>
    <ImageWrapper>
      <ImageLoader />
    </ImageWrapper>
    <LoadingBackground style={{ width: '60%' }}>
      <Title>L</Title>
    </LoadingBackground>
    <Footer>
      <LoadingBackground style={{ width: 80 }}>
        <CreatedAtText>L</CreatedAtText>
      </LoadingBackground>

      <ProfileBadges
        followers={undefined}
        totalCount={undefined}
        onClick={() => {}}
        isLoading={true}
        showTotalCountAs='badge'
      />
    </Footer>
  </Wrapper>
);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 10,
  padding: 16,
  gap: 8,
  background: 'linear-gradient(24.95deg, #20252B 22.99%, rgba(32, 37, 43, 0.33) 103.83%)',
  flex: 1,
  maxWidth: 468,
  cursor: 'pointer',
  [theme.min(theme.breakpoints.md)]: {
    borderRadius: 15,
    padding: '24px 16px 16px 20px',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    borderRadius: 20,
    padding: `${theme.pxToVw(37)} ${theme.pxToVw(20)} ${theme.pxToVw(20)} ${theme.pxToVw(
      32
    )}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '37px 20px 20px 32px',
  },
}));

const Image = styled('img')<{ position: 'top' | 'center' }>(({ theme, position }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: position,
  cursor: 'pointer',
  borderRadius: 10,
  [theme.min(theme.breakpoints.md)]: {
    borderRadius: 15,
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  width: 60,
  height: 60,
  transition: 'all .3s',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: 10,
  [theme.min(theme.breakpoints.desktop)]: {
    width: theme.pxToVw(80),
    height: theme.pxToVw(80),
    borderRadius: 15,
  },
  [theme.min(theme.breakpoints.max)]: {
    width: 80,
    height: 80,
  },
}));

const ImageLoader = styled(LoadingBackground)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: 10,
  [theme.min(theme.breakpoints.desktop)]: {
    width: theme.pxToVw(80),
    height: theme.pxToVw(80),
    borderRadius: 15,
  },
  [theme.min(theme.breakpoints.max)]: {
    width: 80,
    height: 80,
  },
}));

const Title = styled(Heading5)(({ theme }) => ({
  fontWeight: 900,
  cursor: 'pointer',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const CreatedAtText = styled(Text.SM)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.6)',
  fontWeight: 700,
  fontSize: 14,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 15,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));
