export interface ReportData {
  address: string;
  firstTransactionTime: string;
  lastTransactionTime: string;
  numberOfTransactions: number;
  nftCount: number;
  trustPercentage: number;
  balanceEth: number;
  balanceUsd: number;
  isContract: boolean;
  totalValueReceivedEth: number;
  totalValueReceivedUsd: number;
  totalValueSentEth: number;
  totalValueSentUsd: number;
  isProjectVerified: boolean;
  ownerUsername: string | null;
  erc20Count: number;
  erc20Balance: number;
  erc20BalanceUsd: number;
  ethToUsdConversionRate: number;
}

export enum Trust {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Untrusted = 'Untrusted',
  NewWallet = 'NewWallet',
}

export type TSetter<T> = { [K in keyof T]?: T[K] };

export interface PaginatedResponse<T> {
  totalCount: number;
  results: T[];
  nextPage?: string | number;
  prevPage?: string | number;
}

interface ProfileBase {
  uid: string;
  username: string;
  email: string | null;
  phoneNumber: string | null;
  photoUrl: string | null;
  addresses: string[];
  coverPhoto: string | null;
  description: string | null;
  twitterUrl: string | null;
  discordUrl: string | null;
  openseaUrl: string | null;
}

export interface User extends ProfileBase {
  notificationsEnabled: boolean;
}

export type Profile = ProfileBase;

export interface ProjectAdditionalDetails {
  externalUrl: string | null;
  description: string | null;
  twitterUrl: string | null;
  discordUrl: string | null;
  openseaUrl: string | null;
}

export interface ProjectMetadata extends ProjectAdditionalDetails {
  title: string;
  contractAddress: string;
  photoUrl: string | null;
}

export interface Project extends ProjectMetadata {
  uid: string;
  ownerAddress: string;
  coverPhotoUrl: string | null;
  isVerified: boolean;
  contractCreatedAt: number;
}

export interface ProjectDetails {
  tokenSupply: number;
  floorPrice: number;
  holdersCount: number;
}

export type ProjectListItem = Pick<
  Project,
  'uid' | 'photoUrl' | 'contractAddress' | 'title'
>;

export interface NFT {
  title: string;
  contractAddress: string;
  tokenId: string;
  photoUrls: string[];
  isProfile: boolean;
  userUid: string;
  userAddress: string;
  priceCurrency: string;
  description: string;
  price: number;
}

export interface Follower {
  userUid: string;
  photoUrl: string | null;
  username: string;
}

export interface Following {
  entityId: string | null;
  photoUrl: string | null;
  title: string | null;
  address: string;
  isContract: boolean;
}

export interface Transaction {
  hash: string;
  from: string;
  to: string;
  value: string;
  blockNumber: string;
  timeStamp: number;
  gasUsed: string;
  gasPrice: string;
  methodId: string;
  functionName: string;
  isError: string;
  txType: TransactionType;
}

export enum TransactionType {
  Normal = 'normal',
  Token = 'token',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export enum AppMessage {
  RegisterToSeeFollowers,
  RegisterToFollow,
  RegisterToEndorse,
  SocialNetworksNotAdded,
  RegisterToSeeEndorsments,
  RegisterOrSignInToReportAddress,
}

export interface ProjectAnalyticsTransactionItem {
  contractAddress: string;
  executionDate: number;
  inTxCount: number;
  outTxCount: number;
}

export enum NotificationType {
  Endorse = 'endorse',
  NewTx = 'new_tx',
}

interface NotificationBase {
  id: number;
  userUid: string;
  createdAt: number;
  isRead: boolean;
}

interface EndorseNotificationContent {
  type: NotificationType.Endorse;
  userUid: string;
  username: string;
  userPhotoUrl: string | null;
  projectUid: string;
  projectTitle: string;
  projectPhotoUrl: string | null;
  projectAddress: string;
}

export interface EndorseNotification extends NotificationBase {
  type: NotificationType.Endorse;
  content: EndorseNotificationContent;
}

interface NewTxNotificationContent {
  type: NotificationType.NewTx;
  userUid: string;
  username: string;
  userPhotoUrl: string | null;
  address: string;
}

export interface NewTxNotification extends NotificationBase {
  type: NotificationType.NewTx;
  content: NewTxNotificationContent;
}

export interface Notification extends NotificationBase {
  type: NotificationType;
  content: NewTxNotificationContent | EndorseNotificationContent;
}
