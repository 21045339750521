import { useQuery } from 'react-query';

import { ProjectApi } from '../../api/ProjectApi';
import { QueryKey } from '../query-keys';

import { useProfileByParamId } from '../profile/useProfile';

export const useProfileProjects = (
  addressOrPorfileUsername: string | null | undefined
) => {
  return useQuery(
    QueryKey.ProfileProjectes(addressOrPorfileUsername!),
    () => ProjectApi.getProjectsByAddressOrUsername(addressOrPorfileUsername!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!addressOrPorfileUsername,
    }
  );
};

export const useProfileProjectsByUsername = () => {
  const { data: profile } = useProfileByParamId();
  return useProfileProjects(profile?.username);
};
